<template>
  <div class="page privacy_p">
    <h2>{{ $t("privacyPageTitle") }}</h2>
    <div>
      <p v-for="(item, index) in contentArr" :key="index">
        <b>{{ item.title }}</b>
        <span :style="{ 'word-break': languageCode == 1 ? '' : 'keep-all' }">{{
          item.content
        }}</span>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    // 多语言隐私政策
    contentArr() {
      let contentArr = this.$store.getters.privacyContents;
      return contentArr;
    },
  },
  created() {
    this.languageCode = localStorage.getItem("langCode") || 1;
  },
  data() {
    return {
      languageCode: 1,
    };
  },
  mounted() {
    this.$store.dispatch("commitMenuStatus", false);
  },
};
</script>
<style lang="stylus" scoped>
.privacy_p {
  padding: 2rem 5rem;
  line-height: 18px;
  color: #666666;
  box-sizing: border-box;
  width: 100vw;
  min-height: calc(100vh - 60px);

  h2 {
    text-align: center;
  }

  b {
    display: block;
    margin: 0.5rem 0;
  }

  span {
    display: block;
    padding: 10px 0 20px 0;
    width: 100%;
    overflow: hidden;
    white-space: pre-wrap;
  }
}
</style>
