var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page privacy_p" }, [
    _c("h2", [_vm._v(_vm._s(_vm.$t("privacyPageTitle")))]),
    _c(
      "div",
      _vm._l(_vm.contentArr, function(item, index) {
        return _c("p", { key: index }, [
          _c("b", [_vm._v(_vm._s(item.title))]),
          _c(
            "span",
            {
              style: { "word-break": _vm.languageCode == 1 ? "" : "keep-all" }
            },
            [_vm._v(_vm._s(item.content))]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }